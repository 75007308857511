import { getCurrentLanguage } from 'utils/src/getCurrentLanguage';
import { IPublicationApiEntity } from '.';
import { apiClient } from '../../../../apps/crossword-maker/src/shared/apiClient';

export type TCreate = (name: string) => Promise<IPublicationApiEntity>;

export const create: TCreate = async (name): Promise<IPublicationApiEntity> => {
	const language = getCurrentLanguage();

	const response = await apiClient().post<IPublicationApiEntity>(
		`/rest/${language}/publication`,
		{
			name,
		},
	);

	return response.data;
};
