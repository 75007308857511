import { apiClient } from '../../../../apps/crossword-maker/src/shared/apiClient';
import { IUserApiEntity } from './User.api.types';

export type TGetSelf = () => Promise<IUserApiEntity>;

export const getSelf: TGetSelf = async () => {
	const language = localStorage.getItem('current_language') || 'no';

	const response = await apiClient().get<IUserApiEntity>(`/rest/${language}/user/my`);

	return response.data;
};
