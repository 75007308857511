import { getCurrentLanguage } from 'utils/src/getCurrentLanguage';
import { apiClient } from '../../../../apps/crossword-maker/src/shared/apiClient';

export type TDeleteOne = (id: string) => Promise<{ ok: boolean }>;

export const deleteOne: TDeleteOne = async (id) => {
	const language = getCurrentLanguage();

	const response = await apiClient().delete(`/rest/${language}/crossword/${id}`);

	return { ok: true, response: response };
};
