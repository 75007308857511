import { getCurrentLanguage } from 'utils/src/getCurrentLanguage';
import { apiClient } from '../../../../apps/crossword-maker/src/shared/apiClient';
import { PaginationResponseData } from '../../../../apps/crossword-maker/src/shared/types/RestApi.types';
import {
  IWordApiEntity,
  IWordApiRequestData,
  IWordApiResponse,
} from './Word.api.types';

export type TGetMany = (
	data?: IWordApiRequestData,
) => Promise<IWordApiResponse>;

export const getMany: TGetMany = async (data) => {
	const language = getCurrentLanguage();

	const response = await apiClient().get<PaginationResponseData<IWordApiEntity>>(
		`/rest/${language}/words`,
		{ params: { ...data } },
	);

	return {
		items: response.data.items,
		paginationInfo: response.data.paginationInfo,
	};
};
