import type { ICrosswordApiEntity } from "data/Api/Crossword";
import { createContext, Dispatch, SetStateAction } from "react";
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from "react-query";
import { GenerationStatsApiEntity } from "../CrosswordEditor/Views/EditorToolbar/Api/generationStats.api";
import { ECrosswordType, type IGenerateCrosswordMercureEvent } from "./Crossword.types";

export interface ICrosswordContext {
  crosswordType: ECrosswordType;
  currentCrossword?: ICrosswordApiEntity;
  setCurrentCrossword: (crossword: ICrosswordApiEntity | undefined) => void;
  setCurrentCrosswordRaw: Dispatch<
    SetStateAction<ICrosswordApiEntity | undefined>
  >;
  refetchCurrentCrossword: () => Promise<boolean>;
  isSyncingFromCloud: boolean;
  isSyncingToCloud: boolean;
  crosswordIsCloudSynced: boolean;
  fullResetCrossword: () => void;
  softResetCrossword: () => void;
  updateGeneratedDataFromEvent: (event: IGenerateCrosswordMercureEvent) => void;
  showEditCrosswordModal: boolean;
  setShowEditCrosswordModal: Dispatch<SetStateAction<boolean>>;
  syncCurrentCrosswordWithServer: () => void;
  currentCrosswordIsLocked: boolean;
  isGeneratingCrossword: boolean;
  setIsGeneratingCrossword: Dispatch<SetStateAction<boolean>>;
  generateCrossword: () => Promise<void>;
  canGenerate: boolean;
  loadingCrosswordStats: boolean;
  crosswordStatsData: GenerationStatsApiEntity | undefined;
  refetchCrosswordStats: <TPageData>(options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined) => Promise<QueryObserverResult<GenerationStatsApiEntity | undefined, unknown>>
}

export const CrosswordContext = createContext<ICrosswordContext>({
  crosswordType: ECrosswordType.CLUES_IN_SQUARES,
  currentCrossword: undefined,
  setCurrentCrossword: () => {
  },
  setCurrentCrosswordRaw: () => {
  },
  refetchCurrentCrossword: async () => false,
  isSyncingFromCloud: false,
  isSyncingToCloud: false,
  crosswordIsCloudSynced: false,
  fullResetCrossword: () => {
  },
  softResetCrossword: () => {
  },
  updateGeneratedDataFromEvent: () => {
  },
  showEditCrosswordModal: false,
  setShowEditCrosswordModal: () => {
  },
  syncCurrentCrosswordWithServer: () => {
  },
  currentCrosswordIsLocked: false,
  isGeneratingCrossword: false,
  setIsGeneratingCrossword: () => {
  },
  generateCrossword: async () => {
  },
  canGenerate: false,
  loadingCrosswordStats: false,
  crosswordStatsData: undefined,
  refetchCrosswordStats: async () => {
    return {} as any;
  }
});
