import { getCurrentLanguage } from "utils/src/getCurrentLanguage";
import { apiClient } from "../../../../apps/crossword-maker/src/shared/apiClient";

export type IExportUsedWordsToTxtApi = (
	id: string,
) => Promise<any | null>;

export const exportUsedWordsToTxtApi: IExportUsedWordsToTxtApi = async (
	id,
) => {
	const language = getCurrentLanguage();

	const response = await apiClient().get<any | null>(
		`/rest/${language}/crossword/${id}/clues/export-to-txt`,
	);

	return response.data;
};
