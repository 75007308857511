import { useAuth0 } from "@auth0/auth0-react";
import { FC, useMemo } from "react";
import { ButtonWithIcon } from "ui/button";
import { Menu, MenuItem } from "ui/menu";
import { useNavigateToPath } from "utils/hooks";
import { useAppContext } from "../../../App/App.hooks";
import { useAuth } from "../../../Auth/Auth.hooks";
import { Avatar, StyledTopBar, TopBarHeading, TopBarHeadingTitile, TopBarIcon, TopBarStatus, TopBarStatusTag, TopBarTitle } from "./TopBar.style";

export const TopBar: FC = () => {
  const { topBarState } = useAppContext();
  const { navigateToPath } = useNavigateToPath();
  const { user } = useAuth0();
  const { logout } = useAuth();

  const renderIcon = useMemo(() => {
    const { Icon, iconPath } = topBarState;

    if (Icon && iconPath) {
      return (
        <TopBarIcon>
          <ButtonWithIcon
            onClick={() => {
              navigateToPath(iconPath);
            }}
          >
            <Icon size={24} />
          </ButtonWithIcon>
        </TopBarIcon>
      );
    }

    if (Icon && !iconPath) {
      return (
        <TopBarIcon>
          <ButtonWithIcon>
            <Icon size={24} />
          </ButtonWithIcon>
        </TopBarIcon>
      );
    }

    return null;
  }, [topBarState, navigateToPath]);

  const statusMapping: { [key: string]: string } = {
    in_review: "In review",
    completed: "Completed",
    in_progress: "In progress",
    in_generation_queue: "In generation queue",
  };

  return (
    <StyledTopBar>
      <TopBarTitle>
        {renderIcon}

        {topBarState?.title && (
          <TopBarHeading>
            <TopBarHeadingTitile>
              {topBarState.title}
            </TopBarHeadingTitile>

            {topBarState?.crosswordStatus && (
              <TopBarStatusTag status={topBarState.crosswordStatus}>{statusMapping[topBarState?.crosswordStatus]}</TopBarStatusTag>
            )}
            {topBarState?.isCampaignMode && (
              <TopBarStatus>Campaign</TopBarStatus>
            )}
          </TopBarHeading>
        )}
      </TopBarTitle>
      {(user?.name || user?.picture) && (
        <Menu
          triggerElement={
            <Avatar>
              <img src={user.picture} aria-label="avatar" title={user.name} />
            </Avatar>
          }
        >
          <MenuItem onClick={logout}>Log out</MenuItem>
        </Menu>
      )}
    </StyledTopBar>
  );
};
