import {
  IExportUsedWordsToTxtApi,
  ITexportSvg,
  TCrosswordSolutionWords,
  TDeleteOne,
  TGenerateCrossword,
  TGetMany,
} from ".";

import {
  ECrosswordTarget,
  ECrosswordType,
  TCrosswordStatus
} from "../../../../apps/crossword-maker/src/Domain/Crossword/Crossword.types";
import { IPaginationApi } from "../../../../apps/crossword-maker/src/shared/types/RestApi.types";
import { RequiredFields } from "../../shared/types/requiredFields";
import { IGridPatternApiEntity } from "../GridPattern";

export interface ICrosswordApiDataSource {
	getMany: TGetMany;
	deleteOne: TDeleteOne;
	generateCrossword: TGenerateCrossword;
	exportSvg: ITexportSvg;
	exportUsedWordsToTxt: IExportUsedWordsToTxtApi;
	getCrosswordSolutionWords: TCrosswordSolutionWords;
}

export interface GeneratedDataClue {
	clue: string;
	difficulty: number;
	selected?: boolean;
	custom?: boolean;
}

export interface GeneratedData {
	clues: GeneratedDataClue[];
	word: string;
	direction: "H" | "V";
	x: number;
	y: number;
}

export interface IFormattedClue {
	text: string;
	size: number;
	dx: number; // TODO: evaluate the need for this
	dy: number; // TODO: evaluate the need for this
}

export interface IFormattedCluesData {
	x: number;
	y: number;
	divider: "D" | "N" | "U";
	clues: IFormattedClue[][];
}

export interface ICrosswordSettings {
	amount: number;
	difficultyFrom: number;
	difficultyTo: number;
	target: ECrosswordTarget;
	type: ECrosswordType;
	isCampaign: boolean;
	gridPatternIds: string[];
	themeIds: string[];
}

export interface ICrosswordGenerationDifficultyAreasCoords {
	x: number,
	y: number,
}

export interface ICrosswordGenerationDifficultyAreasCoordsMinMax {
	min: number,
	max: number
}

export interface ICrosswordGenerationDifficultyAreas {
	brc: ICrosswordGenerationDifficultyAreasCoords;
	ulc: ICrosswordGenerationDifficultyAreasCoords;
	difficulty: ICrosswordGenerationDifficultyAreasCoordsMinMax
}

export interface ICrosswordGenerationSettings {
	timeout?: number | null;
	duplicatesAllowed?: boolean;
	difficultyAreas?: ICrosswordGenerationDifficultyAreas[];
}

export interface ICrosswordStats {
	in_progress: string[];
	generation_started: string[];
	generation_succeeded: string[];
	generation_failed: string[];
	completed: string[];
	in_generation_queue: string[];
  in_review: string[];
}

export interface ICrosswordSet {
	id: string;
	name: string;
	settings: ICrosswordSettings;
	createdAt: string;
	modifiedAt: string;
	publicationId: string;
	createdById: string;
	crosswordIds: string[];
	crosswordStats: ICrosswordStats;
}

export interface ICrosswordApiEntity {
	approvedAt?: string | null;
	approvedById?: string | null;
	createdAt: string;
	createdById: string;
	difficultyFrom: number;
	difficultyTo: number;
	generatedData: GeneratedData[];
	gridPatternData: IGridPatternApiEntity;
	formattedCluesData: IFormattedCluesData[];
	id: string;
	modifiedAt: string;
	name: string;
	publicationId?: string | null;
	status: TCrosswordStatus;
	target: ECrosswordTarget;
	themeId: string;
	type: ECrosswordType;
	generatedDataExist?: boolean;
	gridPatternDataExist?: boolean;
	isCampaign?: boolean;
	isSingle?: boolean;
	isBulk?: boolean;
	crosswordsNumber?: number;
	crosswordSet?: ICrosswordSet;
	password?: string;
	generationSettings?: ICrosswordGenerationSettings;
}

export type TCrosswordApiCreateRequestData = Partial<ICrosswordApiEntity>;

export type BulkCrosswordApiCreateRequestData = RequiredFields<ICrosswordApiEntity & {
	publicationId: string;
}>;

export interface ICrosswordsApiRequestData extends IPaginationApi {
	query?: string;
	name?: string;
	difficultyFrom?: number;
	difficultyTo?: number;
	tag?: string;
	status?: string;
	statuses?: string[];
	type?: ECrosswordType;
	types?: ECrosswordType[];
	tagIds?: string[];
	createdById?: string;
	target?: string
	publicationId?: string;
	createdAtFrom?: string;
	createdAtTo?: string;
	groupBySets?: boolean;
}

export interface ICrosswordApiTagsRequestData {
	tagIds: string[];
}

export interface IUpdateCrosswordRequestData {
	difficultyFrom: number;
	difficultyTo: number;
	target: ECrosswordTarget;
	type: ECrosswordType;
	name: string;
	publicationId: string;
	themeId: string;
	generatedData: GeneratedData[];
	gridPatternData: IGridPatternApiEntity;
	isCampaign?: boolean;
	generationSettings?: ICrosswordGenerationSettings;
	timeoutForAlgorithm?: number | null;
	duplicatesAllowed?: boolean;
}

export enum ECrosswordQueryKey {
	ARCHIVED_CROSSWORDS = "archived-crosswords",
	RECENT_CROSSWORDS = "recent-crosswords",
	PUBLICATIONS = "publications",
}
