import { getCurrentLanguage } from 'utils/src/getCurrentLanguage';
import { apiClient } from '../../../../apps/crossword-maker/src/shared/apiClient';
import { IWordApiEntity, IWordApiUpdatePayload } from './Word.api.types';

export type TUpdate = (
	id: string,
	data: IWordApiUpdatePayload,
) => Promise<IWordApiEntity>;

export const update: TUpdate = async (id, data) => {
	const language = getCurrentLanguage();

	const response = await apiClient().put<IWordApiEntity>(`/rest/${language}/word/${id}`, {
		...data,
	});

	return response.data;
};
