import { getCurrentLanguage } from 'utils/src/getCurrentLanguage';
import { apiClient } from '../../../../apps/crossword-maker/src/shared/apiClient';
import {
  IDummyPatternRequestData,
  IGridPatternApiEntity,
} from './GridPattern.api.types';

export type TDummyPattern = (
	data: IDummyPatternRequestData,
) => Promise<IGridPatternApiEntity>;

export const getDummyPattern: TDummyPattern = async (data) => {
	const language = getCurrentLanguage();

	const response = await apiClient().post<IGridPatternApiEntity>(
		`/rest/${language}/grid-pattern/dummy`,
		data,
	);

	return response.data;
};
