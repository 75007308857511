import { getCurrentLanguage } from 'utils/src/getCurrentLanguage';
import { apiClient } from '../../../../apps/crossword-maker/src/shared/apiClient';
import { ReplaceWordDataType } from '../../Repository/WordRepository/WordRepository.types';

export const wordReplace = async (data: ReplaceWordDataType) => {
	const language = getCurrentLanguage();

	const response = await apiClient().post<ReplaceWordDataType>(
		`/rest/${language}/word-clues/replace`,
		data
	);

	return {
		success: Boolean(response.status < 300),
	};
};
