import { getCurrentLanguage } from 'utils/src/getCurrentLanguage';
import { apiClient } from '../../../../apps/crossword-maker/src/shared/apiClient';
import {
  ICreateGridPatternRequestData,
  IGridPatternApiEntity,
} from './GridPattern.api.types';

export type TCreate = (
	data: ICreateGridPatternRequestData,
) => Promise<IGridPatternApiEntity>;

export const create: TCreate = async (data) => {
	const language = getCurrentLanguage();

	const response = await apiClient().post<IGridPatternApiEntity>(
		`/rest/${language}/grid-pattern`,
		data,
	);

	return response.data;
};
