import { getCurrentLanguage } from 'utils/src/getCurrentLanguage';
import {
  apiClient,
  headers,
} from '../../../../apps/crossword-maker/src/shared/apiClient';
import { IGridPatternApiEntity } from './GridPattern.api.types';

export type TImportFile = (data: FormData) => Promise<IGridPatternApiEntity>;

export const importFile: TImportFile = async (data) => {
	const language = getCurrentLanguage();

	const response = await apiClient().post<IGridPatternApiEntity>(
		`/rest/${language}/grid-pattern/import`,
		data,
		{
			headers: {
				...headers,
				'Content-type': 'multipart/form-data',
			},
		},
	);

	return response.data;
};
