import { getCurrentLanguage } from 'utils/src/getCurrentLanguage';
import { apiClient } from '../../../../apps/crossword-maker/src/shared/apiClient';
import {
  PaginationInfo,
  PaginationResponseData,
} from '../../../../apps/crossword-maker/src/shared/types/RestApi.types';
import {
  IGridPatternApiEntity,
  IGridPatternsRequestData,
} from './GridPattern.api.types';

export type TGetMany = (data: IGridPatternsRequestData) => Promise<{
	items: IGridPatternApiEntity[];
	paginationInfo: PaginationInfo;
}>;

export const getMany: TGetMany = async (data) => {
	const language = getCurrentLanguage();

	const response = await apiClient().get<
		PaginationResponseData<IGridPatternApiEntity>
	>(`/rest/${language}/grid-patterns`, { params: { ...data } });

	return {
		items: response.data.items,
		paginationInfo: response.data.paginationInfo,
	};
};
