import { getCurrentLanguage } from "utils/src/getCurrentLanguage";
import { IPublicationApiEntity } from ".";
import { apiClient } from "../../../../apps/crossword-maker/src/shared/apiClient";
import {
  PaginationResponseData,
  RestApiErrorType,
} from "../../../../apps/crossword-maker/src/shared/types/RestApi.types";

export type TGetMany = () => Promise<IPublicationApiEntity[]>;

export const getMany: TGetMany = async () => {
	const language = getCurrentLanguage();

	const response = await apiClient().get<
		PaginationResponseData<IPublicationApiEntity> | RestApiErrorType
	>(`/rest/${language}/publications`, {
		params: {
			pageSize: 100,
		}
	});

	return response.data.items;
};
