import { useCallback } from 'react';
import { Outlet } from 'react-router-dom';
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import { Layout, LayoutWithSidebar, RouteOutlet } from './AppRoutes.style';
import { Sidebar } from './Components/Sidebar/Sidebar';
import { TopBar } from './Components/TopBar/TopBar';

export const AppRoutesViewModel = () => {
  const SidebarLayout = useCallback(
    () => (
      <LayoutWithSidebar>
        <TopBar />
        <Sidebar />
        <ErrorBoundary>
          <RouteOutlet>
            <Outlet />
          </RouteOutlet>
        </ErrorBoundary>
      </LayoutWithSidebar>
    ),
    [],
  );

  const CleanLayout = useCallback(
    () => (
      <Layout>
        <TopBar />
        <ErrorBoundary>
          <RouteOutlet>
            <Outlet />
          </RouteOutlet>
        </ErrorBoundary>
      </Layout>
    ),
    [],
  );

  return {
    SidebarLayout,
    CleanLayout,
  };
};
