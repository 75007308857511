import { getCurrentLanguage } from "utils/src/getCurrentLanguage";
import { apiClient } from "../../../../apps/crossword-maker/src/shared/apiClient";
import { ITagEntity } from "../../../ui/form/TagsInput/TagsInput.types";

export type TGetTags = (id: string) => Promise<ITagEntity[]>;

export const getTags: TGetTags = async (id) => {
	const language = getCurrentLanguage();

	const response = await apiClient().get<ITagEntity[]>(`/rest/${language}/word/${id}/tags`);

	return response.data;
};
