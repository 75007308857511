import { initial } from 'lodash';
import { styled } from 'stitches';

export const StyledButton = styled('button', {
	position: 'relative',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	border: 'none',
	outline: 'none',
	cursor: 'pointer',
	fontWeight: 500,
	whiteSpace: 'nowrap',
	variants: {
		floating: {
			true: {
				position: 'fixed',
				bottom: '$4',
				right: '$4',
				padding: '$2',
				borderRadius: '$half',
				backgroundColor: '$white',
				boxShadow: '0 0 0 1px $black20',
			},
		},
		mode: {
			primary: {
				backgroundColor: '$indigo',
				color: '$white',
				borderRadius: '$half',
				'&:hover': {
					backgroundColor: '$indigoDark',
				},
				'&:disabled': {
					cursor: 'default',
					backgroundColor: '$indigoLight',

					span: {
						opacity: 0.7,
					},
				},
				'&:focus': {
					border: 'none',
				},
			},
      primaryIndigo: {
        padding: '4px 12px',
        borderRadius: 8,
        fontSize: 14,
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.71,
        letterSpacing: '0.25px',
        color: '$indigoDark',
        backgroundColor: 'rgba(88, 86, 207, 0.1)',
        '&:hover': {
					backgroundColor: 'rgba(88, 86, 207, 0.3)',
				},
				'&:disabled': {
					cursor: 'default',
					backgroundColor: '$indigoLight',

					span: {
						opacity: 0.7,
					},
				},
				'&:focus': {
					border: 'none',
				},
      },
			secondary: {
				backgroundColor: '$white',
				color: '$black80',
				borderRadius: '$half',
				boxShadow: 'inset 0px 0px 0px 1px $colors$black20',
				'&:not(:disabled):hover': {
					boxShadow: 'inset 0px 0px 0px 2px $colors$black',
				},
				'&:disabled': {
					opacity: 0.5,
				},
			},
			tertiary: {
				backgroundColor: 'transparent',
				color: '$black80',
				textDecoration: 'underline',
				'&:hover': {
					fontWeight: 600,
				},
				'&:disabled': {
					opacity: 0.5,
				},
			},
			danger: {
				backgroundColor: 'transparent',
				color: '$red',
				border: '1px solid $red',
				borderRadius: '$half',
				'&:hover': {
					backgroundColor: '$red',
					color: '$white',
				},
				'&:disabled': {
					opacity: 0.5,
				},
			},
			link: {
				backgroundColor: 'transparent',
				color: '$black',
				textDecoration: 'underline',
				fontFamily: '$regular',
				'&:hover': {
					fontWeight: initial,
					color: '$indigoDark',
				},
			},
			floatingAction: {
				position: 'fixed',
				bottom: '$4',
				right: '$4',
				width: '$8 !important',
				height: '$8 !important',
				backgroundColor: '$indigo',
				color: '$white',
				borderRadius: '$xlarge',
				boxShadow:
					'0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 3px 0 rgba(0, 0, 0, 0.09), 0 7px 4px 0 rgba(0, 0, 0, 0.05), 0 13px 5px 0 rgba(0, 0, 0, 0.01), 0 20px 6px 0 rgba(0, 0, 0, 0)',
				'&:hover': {
					backgroundColor: '$indigoDark',
				},
				'&:disabled': {
					backgroundColor: '$indigoLight',
					cursor: 'default',
					pointerEvents: 'none',
				},
				'&:focus': {
					border: '2px solid $yellow',
				},
			},
		},
		size: {
			tight: {
				fontSize: 16,
				padding: 0,
			},
			compact: {
				fontSize: 12,
				padding: '0 $3',
				height: '$4',
			},
			medium: {
				fontSize: 15,
				padding: '0 $3',
				height: '$5',
			},
			default: {
				fontSize: 16,
				padding: '0 $4',
				height: '$5',
			},
			large: {
				fontSize: 16,
				padding: '0 $6',
				height: '$6',
			},
      small: {
        fontSize: 14,
				padding: '0 $2',
				height: '$4',
        borderRadius: 8,
      }
		},
	},
	defaultVariants: {
		mode: 'primary',
		size: 'default',
	},
});

export const TextContainer = styled('span', {
	display: 'flex',
	gap: '$1',
	variants: {
		displaySpinner: {
			true: {
				filter: 'blur(2px)',
			},
		},
	},
});

export const SpinnerContainer = styled('div', {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	position: 'absolute',
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	borderRadius: 'inherit',
});
