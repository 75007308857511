import { getCurrentLanguage } from 'utils/src/getCurrentLanguage';
import { apiClient } from '../../../../apps/crossword-maker/src/shared/apiClient';
import {
  IWordClueApiEntity,
  IWordClueApiUpdateRequestData,
} from './WordClue.api.types';

export type TUpdate = (
	id: string,
	data: IWordClueApiUpdateRequestData,
) => Promise<IWordClueApiEntity>;

export const update: TUpdate = async (id, data) => {
	const language = getCurrentLanguage();

	const response = await apiClient().put<IWordClueApiEntity>(
		`/rest/${language}/word-clue/${id}`,
		{ ...data },
	);

	return response.data;
};
