import axios, { AxiosInstance, isAxiosError } from "axios";
import { errorToast } from "ui/notifications";
import { retrieveToken } from "../../Domain/Auth/Auth.utils";
import { appEnv } from "../../appEnv";

export const headers = {
	"Content-type": "application/json",
};
let apiClientInstance: AxiosInstance = axios.create({
  baseURL: appEnv.REST_API_URL,
  headers
});

export const apiClient = (timeout: number = 20000) => {
  if (!apiClientInstance) {
    apiClientInstance = axios.create({
      baseURL: appEnv.REST_API_URL,
      headers,
      timeout,
    });
  }
  
  apiClientInstance.defaults.timeout = timeout;

  return apiClientInstance
}

apiClientInstance?.interceptors.request.use(
	(config) => {
		// Appends the token to the request header
		const token = retrieveToken();
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	},
);

apiClient().interceptors.response.use(
	(response) => response,
	(error) => {
		if (error.response) {
			// The request was made and the server responded with a status code
			// that falls out of the range of 2xx
			error.response.data.errors.forEach((err: any) => {
				errorToast(err.message);
			});
		} else if (error.request) {
			// The request was made but no response was received
			if (isAxiosError(error)) {
				errorToast(error.message);
			}
		} else {
			// Something happened in setting up the request that triggered an Error
			errorToast(error.message);
		}

		// Rethrow the error so it can be caught in the component
		return Promise.reject(error);
	}
);