import { Book, Gear, GridFour, PuzzlePiece, Question, } from "@phosphor-icons/react";
import { FC, useEffect, useState } from "react";
import { generatePath, useLocation } from "react-router-dom";
import { ISelectOption, Select } from "ui/form";
import { AppPaths } from "../../../App/App.constant";
import { LanguageSwitcherViewModel } from "../../../LanguageSwitcher/LanguageSwitcher.viewmodel";
import { useUserContext } from "../../../User/User.hooks";
import { Nav, NavLink, NavLinkContent, SelectContainer, StyledSidebar } from "./Sidebar.style";

export const Sidebar: FC = () => {
	const { pathname } = useLocation();
	const { accountPermissions, currentLanguage, accountLanguages, setCurrentLanguage } = useUserContext();
	const { languageOptions } = LanguageSwitcherViewModel();
	const [selectValue, setSelectValue] = useState<ISelectOption>();

	useEffect(() => {
		const value = languageOptions.find((option) => option.value === currentLanguage);
		setSelectValue(value);
	}, [languageOptions, currentLanguage]);

	const puzzleSetsUrl = generatePath(AppPaths.puzzleSet, {
		puzzle: undefined,
	});

	return (
		<StyledSidebar>
			<Nav>
				<NavLink
					to={AppPaths.root}
					active={
						pathname === AppPaths.crossword.root ||
						pathname === AppPaths.crossword.archive
					}
				>
					<NavLinkContent>
						<GridFour
							size={20}
							weight={
								pathname === AppPaths.crossword.root ||
								pathname === AppPaths.crossword.archive
									? 'fill'
									: 'regular'
							}
						/>
						<span>Crossword</span>
					</NavLinkContent>
				</NavLink>
				<NavLink to={puzzleSetsUrl} active={pathname.includes(puzzleSetsUrl)}>
					<NavLinkContent>
						<PuzzlePiece
							size={20}
							weight={pathname.includes(puzzleSetsUrl) ? 'fill' : 'regular'}
						/>
						<span>Puzzle sets</span>
					</NavLinkContent>
				</NavLink>
				{/* <NavLink to={AppPaths.crossword.archive} active={pathname === AppPaths.crossword.archive}>
					<NavLinkContent>
						<Archive
							size={20}
							weight={pathname === AppPaths.crossword.archive ? 'fill' : 'regular'}
						/>
						<span>Archive</span>
					</NavLinkContent>
				</NavLink> */}
				{accountPermissions.canManageWordlist && (
					<NavLink
						to={AppPaths.wordList}
						active={pathname === AppPaths.wordList}
					>
						<NavLinkContent>
							<Book
								size={20}
								weight={pathname === AppPaths.wordList ? 'fill' : 'regular'}
							/>
							<span>Wordlist</span>
						</NavLinkContent>
					</NavLink>
				)}
			</Nav>
			<Nav>
				{!!accountLanguages.length && (
					<SelectContainer>
						<Select
							options={languageOptions}
							value={selectValue}
							menuPlacement='top'
							placeholder='Choose language'
							onChange={(option) => {
								if (option) {
									setSelectValue(option);
									setCurrentLanguage(option.value);
								}
							}}
						/>
					</SelectContainer>
				)}
				<NavLink to={AppPaths.settings} active={pathname === AppPaths.settings}>
					<NavLinkContent>
						<Gear
							size={20}
							weight={pathname === AppPaths.settings ? 'fill' : 'regular'}
						/>
						<span>Settings</span>
					</NavLinkContent>
				</NavLink>
				<NavLink to={AppPaths.help} active={pathname === AppPaths.help}>
					<NavLinkContent>
						<Question
							size={20}
							weight={pathname === AppPaths.help ? 'fill' : 'regular'}
						/>
						<span>Help</span>
					</NavLinkContent>
				</NavLink>
			</Nav>
		</StyledSidebar>
	);
};
