import { getCurrentLanguage } from 'utils/src/getCurrentLanguage';
import { apiClient } from '../../../../apps/crossword-maker/src/shared/apiClient';

export type TCrosswordSolutionWords = (ids: string[]) => Promise<any | null>;

export const getCrosswordSolutionWords: TCrosswordSolutionWords = async (
	ids,
) => {
	const language = getCurrentLanguage();

	const response = await apiClient().post<any | null>(
		`/rest/${language}/crosswords/solution-words/export-to-txt`,
		{
			crosswordIds: ids,
		},
	);

	return response.data;
};
