import { getCurrentLanguage } from 'utils/src/getCurrentLanguage';
import { apiClient } from '../../../../apps/crossword-maker/src/shared/apiClient';
import {
  IWordClueApiCreateRequestData,
  IWordClueApiEntity,
} from './WordClue.api.types';

export type TCreate = (
	data: IWordClueApiCreateRequestData,
) => Promise<IWordClueApiEntity>;

export const create: TCreate = async (data) => {
	const language = getCurrentLanguage();

	const response = await apiClient().post<IWordClueApiEntity>(`/rest/${language}/word-clue`, {
		...data,
	});

	return response.data;
};
