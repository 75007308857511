import { getCurrentLanguage } from 'utils/src/getCurrentLanguage';
import { apiClient } from '../../../../apps/crossword-maker/src/shared/apiClient';
import {
  IGridPatternApiEntity,
  IUpdateGridPatternRequestData,
} from './GridPattern.api.types';

export type TUpdate = (
	id: string,
	entity: IUpdateGridPatternRequestData,
) => Promise<IGridPatternApiEntity>;

export const update: TUpdate = async (id, entity) => {
	const language = getCurrentLanguage();

	const response = await apiClient().put<IGridPatternApiEntity>(
		`/rest/${language}/grid-pattern/${id}`,
		{ ...entity },
	);

	return response.data;
};
